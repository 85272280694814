<template>
  <div class="organization-expense-refunds">



    <v-data-table
        class="subscriptions-list"
        :headers="headers"
        :loading="loading"
        :items="periods"
        :options.sync="options"
        :server-items-length="total"
    >


      <template #item.startDate="{item}">
        {{ formatUtcDate(item.startDate) }} <v-icon>mdi-chevron-right</v-icon> {{ formatUtcDate(item.endDate) }}
      </template>


      <template #item.status="{item}">
        {{ $t('expense-refund.status.' + item.status) }}
      </template>

      <template #item.options="{item}">
        <router-link :to="{name: 'organizations.expense-periods.manage', params: {periodId: item.id} }">
          <v-icon>mdi-arrow-right-thick</v-icon>
        </router-link>
      </template>

    </v-data-table>


  </div>
</template>

<script>

import ExpenseRefundRepository from "@repository/ExpenseRefundRepository";
import dayjs from "dayjs";

export default {
  data() {
    return {
      loading: false,
      options: null,
      total: 0,
      headers: [
        {text: this.$tc('expense-refund.period'), value: 'startDate'},
        {text: this.$t('status'), value: 'status'},
        {value: 'options'},
      ],
      periods: [],
    }
  },

  mounted() {

  },

  watch: {

    options() {
      this.loadData()
    },

  },

  computed: {
    organization() {
      return this.$parent.organization ?? null
    }
  },

  methods: {
    loadData() {
      let filter = {
        organizationId: this.organization?.id
      }

      if (this.options.itemsPerPage > -1) {
        filter.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      filter.limit = this.options.itemsPerPage


      if (this.options.sortBy.length > 0) {
        filter.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        filter.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }
      this.loading = true
      ExpenseRefundRepository.periods(filter).then(response => {
        this.total = response.total
        this.periods = response.items
        this.loading = false
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
.organization-expense-refunds {

}


</style>
