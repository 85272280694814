<template>
  <div class="organization-form  pa-10" v-if="organization">

    <!-- Wizard de changement d'organization pour les bornes et customers -->
    <v-dialog class="modal-confirmation" max-width="50%" v-model="moveContentModal.displayed">
      <form @submit.prevent="submitMoveContent">
        <v-card>
          <v-toolbar
              dark
              color="primary"
              height="48px"
          >
            <v-toolbar-title>{{ $t('organization.move-content.title') }}</v-toolbar-title>
            <v-spacer /> <!-- Affiche le bouton à droite de la barre -->
            <v-btn
                icon
                dark
                @click="moveContentModal.displayed = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <div class="text-center title">{{ $t('organization.move-content.content-was-found') }}</div>
          </v-card-text>

          <!-- Choix de la nouvelle organization -->
          <v-row style="margin: 0;">
            <!-- Clients -->
            <v-col v-if="moveContentModal.customers.length > 0">
              <v-card-text>
                <div style="margin-bottom: 8px">
                  <strong>{{ $tc("customer.customer", 2) }}</strong> ({{ moveContentModal.customers.length }})
                </div>
                <!-- TODO: empecher l'ajout à une orga type 'group' -->
                <tree-select-organization
                    :label="$t('organization.to')"
                    v-model="moveContentModal.toOrganizationCustomers"
                    openDirection="below"
                    :append-to-body="true"
                ></tree-select-organization>
              </v-card-text>
            </v-col>

            <v-divider vertical></v-divider>
            <!-- Points de charge -->
            <v-col v-if="moveContentModal.chargingPoints.length > 0">
              <v-card-text>
                <div style="margin-bottom: 8px">
                  <strong>{{ $t('charging-point.charging-points') }}</strong> ({{ moveContentModal.chargingPoints.length }})
                </div>

                <!-- TODO: empecher l'ajout à une orga type 'group' -->
                <tree-select-organization
                    :label="$t('organization.to')"
                    v-model="moveContentModal.toOrganizationChargingPoints"
                    openDirection="below"
                    :append-to-body="true"
                ></tree-select-organization>
              </v-card-text>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type="submit" text color="primary" :disabled="!canValidateMoveContent">{{ $t('validate') }}</v-btn>
            <v-btn type="button" text @click="moveContentModal.displayed = false">{{ $t('cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>

    <title-page icon="ico-organization">
      {{ $t(edit ? 'organization.edit-organization' : 'organization.add-organization') }}
    </title-page>


    <v-form ref="form" class="form-grid" @submit.prevent="sendData">
      <headline>{{ $t('organization.general-information') }}</headline>
      <v-row>
        <v-col cols="12" md="6">

          <form-field
              :label="$t('name')"
              v-model="organization.name"
              :rules="[commonRules.required]"
          />
          <form-field
              :label="$t('phone')"
              v-model="organization.phone"
          />
          <form-field
              :label="$t('organization.parent-organization')"
              ref="organizationTree"
              type="tree-select-organization"
              v-model="organization.parentId"
              :exclude="edit ? $route.params.id : null"
          />

          <form-field
              :label="$t('country')"
              type="available-select"
              input-type="country"
              v-model="organization.country"
              :placeholder="inheritData('country')"
              clearable
          />

          <form-field
              :label="$t('currency')"
              type="available-select"
              input-type="currency"
              v-model="organization.currency"
              :placeholder="inheritData('currency')"
              clearable
          />


        </v-col>
        <v-col cols="12" md="6">

          <form-field
              :label="$t('main-language')"
              type="available-select"
              input-type="language"
              v-model="organization.language"
              :placeholder="inheritData('language')"
              clearable
          />


          <form-field
              :label="$t('organization.brand-identifier')"
              v-model="organization.brandIdentifier"
              :placeholder="inheritData('brandIdentifier')"
              clearable
          />

          <form-field
              type="select"
              :label="$t('organization.organization-type')"
              v-model="organization.type"
              :items="[
                  { text: $t('organization.type.company'), value: 'company' },
                  { text: $t('organization.type.individual'), value: 'individual' },
                  { text: $t('organization.type.group'), value: 'group' },
              ]"
              :placeholder="inheritData('type')"
              :rules="[
                commonRules.required
              ]"
              clearable
          />

          <form-field
              :label="$t('organization.service-level')"
              v-model="organization.serviceLevel"
              :placeholder="inheritData('serviceLevel')"
              clearable
          />


        </v-col>
      </v-row>

      <headline>{{ $t('organization.contact-person') }}</headline>

      <v-row   class="contact" >
        <template v-if="organization.inheritData">
          <v-col cols="12" md="6" v-for="(contact, i) in organization.inheritData.contacts" :key="`inherit-contact-${i}`">
            <div>

              <headline>
                Contact ({{ $t('inherit') }})
              </headline>


              <form-field
                  :label="$t('firstname')"
                  :placeholder="contact.firstname"
                  readonly
              />

              <form-field
                  :label="$t('lastname')"
                  :placeholder="contact.lastname"
                  readonly
              />

              <form-field
                  :label="$t('email')"
                  :placeholder="contact.email"
                  readonly
              />

              <form-field
                  :label="$t('phone')"
                  :placeholder="contact.phone"
                  readonly
              />

            </div>
          </v-col>
        </template>
        <v-col cols="12" md="6" v-for="(contact, i) in organization.contacts" :key="`contact-${i}`">

          <div>

            <headline>
              Contact {{ i + 1 }}
              <v-btn icon class="close" @click="removeContact(i)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </headline>


            <form-field
                :label="$t('firstname')"
                v-model="contact.firstname"
            />

            <form-field
                :label="$t('lastname')"
                v-model="contact.lastname"
            />

            <form-field
                :label="$t('email')"
                v-model="contact.email"
                input-type="email"
                :rules="[commonRules.email]"
            />

            <form-field
                :label="$t('phone')"
                v-model="contact.phone"
                type="phone"
            />

          </div>

        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center align-center add">
          <v-btn text  @click="addContact">
            {{ $t('organization.add-contact') }}
          </v-btn>
        </v-col>
      </v-row>

      <headline>{{ $t('organization.invoice-information') }}</headline>
      <v-row>
        <v-col cols="12" md="6">

          <form-field
              :label="$t('name')"
              v-model="organization.invoiceContactName"
              :placeholder="inheritData('invoiceContactName')"
              clearable
          />

          <form-field
              :label="$t('number')"
              v-model="organization.invoiceContactAddress.number"
              :placeholder="inheritData('invoiceContactAddress.number')"
              clearable
          />

          <form-field
              :label="$t('street')"
              v-model="organization.invoiceContactAddress.street"
              :placeholder="inheritData('invoiceContactAddress.street')"
              clearable
          />

          <form-field
              :label="$t('city')"
              v-model="organization.invoiceContactAddress.city"
              :placeholder="inheritData('invoiceContactAddress.city')"
              clearable
          />

          <form-field
              :label="$t('zipcode')"
              v-model="organization.invoiceContactAddress.zipcode"
              :placeholder="inheritData('invoiceContactAddress.zipcode')"
              clearable
          />

          <form-field
              :label="$t('state')"
              v-model="organization.invoiceContactAddress.state"
              :placeholder="inheritData('invoiceContactAddress.state')"
              clearable
          />

          <form-field
              :label="$t('country')"
              type="available-select"
              input-type="country"
              v-model="organization.country"
              :placeholder="inheritData('invoiceContactAddress.country')"
              clearable
          />




        </v-col>
        <v-col cols="12" md="6">

          <form-field
              :label="$t('billing-details.legal-form')"
              v-model="organization.legalForm"
              clearable
          />

          <form-field
              :label="$t('billing-details.capital-amount')"
              v-model.number="organization.capitalAmount"
              input-type="number"
              clearable
          />

          <form-field
              :label="$t('organization.registration-number')"
              v-model="organization.registrationNumber"
          />



          <form-field
              :label="$t('vat-number')"
              v-model="organization.vatNumber"
              :placeholder="inheritData('vat')"
              clearable
          />



          <form-field
              :label="$t('email')"
              v-model="organization.invoiceContactEmail"
              input-type="email"
              :rules="[commonRules.email]"
              :placeholder="inheritData('invoiceContactEmail')"
              clearable
          />

          <form-field
              :label="$t('iban.iban')"
              v-model="organization.iban"
              :placeholder="inheritData('iban.iban')"
              clearable
          />

          <form-field
              :label="$t('iban.bic')"
              v-model="organization.bic"
              :placeholder="inheritData('iban.bic')"
              clearable
          />
        </v-col>
      </v-row>



      <headline>{{ $t('organization.operator-parameters-cpo') }}</headline>
      <v-row>
        <v-col cols="12" md="6">

          <form-field
              :label="$t('organization.roaming-identifier')"
              v-model="organization.cpoOperator.id"
              type="select"
              :items="cpoOperators"
              item-value="id"
              :placeholder="edit ? `${inheritData('cpoOperator.name')} (${inheritData('cpoOperator.id')})` : null"
              clearable
          >
            <template #item="{item}">
              {{ item.name }} ({{ item.id }})
            </template>

            <template #selection="{item}">
              {{ item.name }} ({{ item.id }})
            </template>
          </form-field>

          <form-field
              :label="`${ $t('organization.heartbeat-frequency') } (${ $t('seconds') })`"
              v-model.number="organization.cpoHeartbeatFrequency"
              input-type="number"
              :placeholder="inheritData('cpoHeartbeatFrequency')"
              clearable
          />

          <form-field
              :label="$t('organization.missed-heartbeats')"
              v-model.number="organization.cpoMissedHeartbeats"
              input-type="number"
              :placeholder="inheritData('cpoMissedHeartbeats')"
              clearable
          />

          <form-field
              v-if="edit"
              :label="$t('organization.cpo-private')"
              type="switch"
              v-model="organization.cpoPrivate"
              :placeholder="inheritData('cpoPrivate')"
          />


        </v-col>
        <v-col cols="12" md="6">

          <v-row></v-row>

          <form-field
              :label="$t('organization.emp-fee-for-service')"
              ref="organizationTreeCpoOneServiceEmpOrganizationId"
              type="tree-select-organization"
              v-model="organization.cpoOneServiceEmpOrganizationId"
              :placeholder="inheritData('cpoOneServiceEmpOrganizationId')"
              :exclude="edit ? $route.params.id : null"
          />


          <form-field
              :label="$t('organization.whitelist-rfids')"
              type="switch"
              v-model="organization.cpoRfidOnWhiteList"
              :placeholder="inheritData('cpoRfidOnWhiteList')"
          />

        </v-col>
      </v-row>

      <v-row v-if="edit && organization.cpoPrivate">
        <v-col class="text-center">
          <v-btn small @click="openCpoPrivateAccess">
            {{ $t('organization.cpo-private-access.manage') }}
          </v-btn>
        </v-col>
      </v-row>


      <headline>{{ $t('organization.mobility-provider-information-emp') }}</headline>
      <v-row>
        <v-col cols="12" md="6">


          <form-field
              :label="$t('organization.roaming-identifier')"
              v-model="organization.empOperator.id"
              type="select"
              :items="empOperators"
              item-value="id"
              :placeholder="edit ? `${inheritData('empOperator.name')} (${inheritData('empOperator.id')})` : null"
              clearable
          >
            <template #item="{item}">
              {{ item.name }} ({{ item.id }})
            </template>

            <template #selection="{item}">
              {{ item.name }} ({{ item.id }})
            </template>
          </form-field>


          <form-field
              :label="$t('organization.minimum-monthly-fee')"
              v-model.number="organization.empMinimumMonthlyFee"
              input-type="number"
              :placeholder="inheritData('empMinimumMonthlyFee')"
              clearable
          />

          <form-field
              :label="$t('organization.authorize-rfid-ordering')"
              type="switch"
              v-model="organization.empAllowRfidOrdering"
              :placeholder="inheritData('empAllowRfidOrdering')"
          />

          <form-field
              :label="$t('organization.rfid-tags-price')"
              v-model.number="organization.empRfidTagPrice"
              input-type="number"
              :placeholder="inheritData('empRfidTagPrice')"
              clearable
          />

          <form-field
              :label="$t('organization.price-of-rfid-at-registration')"
              v-model.number="organization.empRfidTagRegisterPrice"
              input-type="number"
              :placeholder="inheritData('empRfidTagRegisterPrice')"
              clearable
          />

          <form-field
              v-if="organization.type !== 'group'"
              :label="$t('organization.allow-deferred-payment')"
              v-model="organization.empAllowDeferredPayment"
              type="switch"
              :disabled="!currentUser.isZE"
          />

        </v-col>
        <v-col cols="12" md="6">

          <v-row></v-row>

          <form-field
              :label="$t('organization.minimum-amount-for-fee-for-service')"
              v-model.number="organization.empMinimumAmountForFeeForService"
              input-type="number"
              :placeholder="inheritData('empMinimumAmountForFeeForService')"
              clearable
          />

          <form-field
              :label="$t('organization.margin-for-fee-for-service')"
              v-model.number="organization.empMarginForFeeForService"
              input-type="number"
              :placeholder="inheritData('empMarginForFeeForService')"
              clearable
          >
            <template #append>
              %
            </template>
          </form-field>

          <form-field
              type="phone"
              :label="$t('organization.customer-support-phone')"
              v-model="organization.empSupportPhoneNumber"
              :placeholder="inheritData('empSupportPhoneNumber')"
              clearable
          />

          <form-field
              :label="$t('organization.customer-support-email-address')"
              v-model="organization.empSupportEmail"
              :rules="[commonRules.email]"
              input-type="email"
              :placeholder="inheritData('empSupportEmail')"
              clearable
          />

        </v-col>

      </v-row>

      <headline>{{ $t('organization.roaming-settings') }}</headline>
      <v-row>
        <v-col cols="12" md="6">
            <form-field
                :label="$t('organization.gireve-emsp-roaming')"
                type="switch"
                v-model="organization.gireveOcpiEmp"
                :placeholder="inheritData('gireveOcpiEmp')"
            />
          <form-field
              :label="$t('organization.gireve-cpo-roaming')"
              type="switch"
              v-model="organization.gireveOcpiCpo"
              :placeholder="inheritData('gireveOcpiCpo')"
          />
        </v-col>
        <v-col cols="12" md="6">
          <form-field
              :label="$t('organization.hubject-roaming')"
              type="switch"
              v-model="organization.hubject"
              :placeholder="inheritData('hubject')"
          />
        </v-col>
      </v-row>



      <v-row class="form-btns">
        <v-col col="12" class="text-center">
          <v-btn type="submit" outlined rounded  color="green">
            <v-icon left>mdi-check</v-icon>
            {{ edit ? $t('update') : $t('create') }}
          </v-btn>
        </v-col>
      </v-row>


      <template v-if="edit">
        <div class="mt-10"></div>

        <headline>{{ $t('organization.pricing') }}</headline>

        <v-row justify="center">
          <v-col cols="6">

            <v-list>
              <template v-for="(tariff, i) in tariffs">
                <v-divider v-if="i > 0" :key="'div-'+tariff.id"></v-divider>
                <v-list-item :key="tariff.id">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ tariff.name }} ({{ $tc('tariff.rule', tariff.rules.length) }})
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <div v-if="tariff.externalReference">
                        {{ $t('external-reference') }} : {{ tariff.externalReference }}
                      </div>
                      <div v-if="tariff.network">
                        {{ $t('network') }} : {{ tariff.network === 'internal' ? 'ZEborne' : (tariff.network ? tariff.network.toUpperCase() : 'none') }}
                      </div>
                      <div v-if="tariff.operatorId">
                        {{ $tc('operator.operator') }} : {{ tariff.operatorId }}
                      </div>
                      <div v-if="tariff.partnerOperatorId">
                        {{ $t('partnerOperator') }} : {{ tariff.partnerOperatorId }}
                      </div>
                      <div v-if="tariff.powerType">
                        {{ $t('charging-point.power-type')}} : {{ tariff.powerType }}<br />
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn text small @click="editTariff(tariff)" v-if="!tariff.inherit">
                      {{ $t('edit') }}
                    </v-btn>
                    <v-btn text small v-else disabled>
                      {{ $t('inherit') }}
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>

          </v-col>
        </v-row>



        <v-row>
          <v-col class="text-right">
            <v-btn @click="createTariff">
              {{ $t('tariff.add-tariff') }}
            </v-btn>
          </v-col>
        </v-row>


        <headline>{{ $t('organization.refund') }}</headline>
        <v-row justify="center">
          <v-col cols="6">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('coming-soon') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </template>


    </v-form>

    <tariff-modal
        ref="tariffModal"
        v-if="organization"
        :organizationId="organization.id"
        :tariffId="selectedTariffId"
        @tariff-updated="onTariffUpdated"
    />

    <cpo-private-access-modal
        ref="cpoPrivateAccess"
        v-if="organization"
        :organization="organization"
    />


  </div>
</template>

<script>
import OrganizationRepository from "@/repository/OrganizationRepository";
import FormField from "@blocks/FormField";
import RulesMixin from "@mixins/RulesMixin";
import OperatorRepository from "@repository/OperatorRepository";
import TariffModal from "@/components/modals/TariffModal";
import CpoPrivateAccessModal from "@/components/modals/CpoPrivateAccessModal";
import TreeSelectOrganization from "@blocks/Select/TreeSelectOrganization.vue";

export default {
  components: {

    TreeSelectOrganization,
    CpoPrivateAccessModal,
    TariffModal,
    FormField,
  },

  mixins: [
      RulesMixin,
  ],

  data() {
    return {
      moveContentModal: {
        displayed: false,
        chargingPoints: [],
        customers: [],
        toOrganizationCustomers: null,
        toOrganizationChargingPoints: null,
      },
      operators: [],
      organization: null,
      selectedTariffId: null,
    }
  },


  mounted() {
    if(this.edit) {
      this.loadData()
    } else {
      this.reset()
      const {oid} = this.$route.query
      if(oid) {
        this.organization.parentId = oid
      }
    }
    this.loadOperators()
  },

  watch: {
    '$route.params': {
      handler(val) {
        if(this.edit) {
          this.loadData()
        } else {
          this.reset()
        }
      },
      deep: true,
    },

    'organization.country'() {
      if(!this.organization.invoiceContactAddress) {
        this.organization.invoiceContactAddress = {
          number: null,
          street: null,
          city: null,
          zipcode: null,
          state: null,
        }
      }
      this.organization.invoiceContactAddress.country = this.organization.country
    }
  },

  computed: {
    edit() {
      return !!this.$route.params.id
    },

    canValidateMoveContent() {
      if(this.moveContentModal.chargingPoints.length > 0 && !this.moveContentModal.toOrganizationChargingPoints) {
        return false
      }
      if(this.moveContentModal.customers.length > 0 && !this.moveContentModal.toOrganizationCustomers) {
        return false
      }
      return true
    },

    cpoOperators() {
      return this.operators.filter(o => o.isCpo)
    },

    empOperators() {
      return this.operators.filter(o => o.isEmp)
    },

    tariffs() {
      let tariffs = []
      this.organization?.inheritData?.tariffs?.forEach(t => {
        t.inherit = true
        tariffs.push(t)
      })
      this.organization.tariffs.forEach(t => {
        t.inherit = false
        tariffs.push(t)
      })
      return tariffs
    },

    breadcrumbs() {

      if(!this.organization?.path) {
        return []
      }

      let items = []
      this.organization.path.split('/').forEach((p,i) => {
        items.push({
          text: p,
          to: {params: {id: this.organization.hierarchies[i]}}
        })
      })

      return items
    }
  },

  methods: {
    loadOperators() {
      OperatorRepository.search().then(operators => {
        this.operators = operators
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
      })
    },


    loadData() {
      let id = this.$route.params.id
      if(id) {
        this.showLoading(true)
        OrganizationRepository.get(this.$route.params.id).then(organization => {
          if(!organization.invoiceContactAddress) {
            organization.invoiceContactAddress = {
              number: null,
              street: null,
              city: null,
              zipcode: null,
              state: null,
              country: "FR"
            }
          }
          if(!organization.cpoOperator) {
            organization.cpoOperator = {
              id: null,
            }
          }
          if(!organization.empOperator) {
            organization.empOperator = {
              id: null,
            }
          }
          if(!organization.inheritData.cpoOperator) {
            organization.inheritData.cpoOperator = {
              id: null,
            }
          }
          if(!organization.inheritData.empOperator) {
            organization.inheritData.empOperator = {
              id: null,
            }
          }

          this.organization = organization
          this.showLoading(false)
        }).catch(err => {
          this.$dialog.notify.error(err.response.data?.message || err.message)
          this.showLoading(false)
        })
      }
    },

    sendData() {
      if(this.$refs.form.validate()) {

        this.showLoading(true)


        this.organization.cpoOperatorId = this.organization.cpoOperator?.id
        this.organization.empOperatorId = this.organization.empOperator?.id


        if(this.edit) {
          OrganizationRepository.update(this.$route.params.id, this.organization).then(result => {
            this.$dialog.notify.success(this.$t("updated-successfully"))
            // this.$refs.organizationTree.refresh()
            this.loadData()
          }).catch(err => {
            if(err.response.data && err.response.data?.message.includes("NotEmptyOrganizationException")) {
              console.log(err.response.data)
              this.moveContentModal.displayed = true
              this.moveContentModal.customers = err.response.data.customers
              this.moveContentModal.chargingPoints = err.response.data.chargingPoints
            } else {
              this.$dialog.notify.error(err.response.data?.message || err.message)
            }

            this.showLoading(false)
          })
        } else {
          OrganizationRepository.create(this.organization).then(result => {
            this.$dialog.notify.success(this.$t("created-successfully"))
            this.$router.push({name: 'organizations.edit', params: {id: result.id}})
            // this.$refs.organizationTree.refresh()
            this.loadData()
          }).catch(err => {
            this.$dialog.notify.error(err.response.data?.message || err.message)
            this.showLoading(false)
          })
        }
      } else {
        this.$dialog.notify.error(this.$t('form-has-errors'))
      }
    },

    submitMoveContent() {
      let contentToMove = []

      for(let i = 0; i < this.moveContentModal.customers.length; i++) {
        contentToMove.push({
          type: 'customer',
          id: this.moveContentModal.customers[i],
          target: this.moveContentModal.toOrganizationCustomers
        });
      }

      for(let i = 0; i < this.moveContentModal.chargingPoints.length; i++) {
        contentToMove.push({
          type: 'chargingPoint',
          id: this.moveContentModal.chargingPoints[i],
          target: this.moveContentModal.toOrganizationChargingPoints
        });
      }

      this.showLoading(true)

      OrganizationRepository.moveContent(this.$route.params.id, contentToMove)
      .then(result => {
        this.moveContentModal.displayed = false
        this.sendData()
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
        this.showLoading(false)
      })
    },

    reset() {
      this.organization = {
        parentId: null,
        name: null,
        brandIdentifier: null,
        language: "fr",
        country: "FR",
        currency: "EUR",
        serviceLevel: null,
        type: 'company',

        contacts: [],
        cpoHeartbeatFrequency: 60,
        cpoOperator: {
          id: null
        },
        cpoMissedHeartBeats: 5,
        cpoOneServiceEmpOrganizationId: null,
        cpoRfidOnWhiteList: false,

        empOperator: {
          id: null
        },
        empAllowRfidOrdering: false,
        empSupportPhone: null,
        empSupportEmail: null,
        empMarginForFeeForService: 10,
        empMinimumAmountForFeeForService: 2,
        empMinimumMonthlyFee: 10,
        empRfidTagPrice: 10,
        empRfidTagRegisterPrice: 5.5,
        empAllowDeferredPayment: false,

        invoiceContactName: null,
        invoiceContactEmail: null,
        invoiceContactAddress: {
          number: null,
          street: null,
          city: null,
          zipcode: null,
          state: null,
          country: "FR"
        },
        tariffs: [],
        vat : null,
      }
    },

    addContact() {
      this.organization.contacts.push({
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
      })
    },


    removeContact(index) {
      this.$dialog.confirm({
        title: this.$t('organization.confirm-delete-contact'),
        text: this.$t('are-you-sure'),
      }).then(result => {
        if(result) {
          this.organization.contacts.splice(index, 1)
        }
      })
    },

    createTariff() {
      this.selectedTariffId = null
      this.$nextTick(() => {
        this.$refs.tariffModal.open()
      })
    },

    editTariff(tariff) {
      this.selectedTariffId = tariff.id
      this.$nextTick(() => {
        this.$refs.tariffModal.open()
      })
    },

    onTariffUpdated() {
      this.loadData()
    },

    inheritData(prop, placeholder = null) {
      if(!this.edit) {
        return null
      }


      let obj = this.organization?.inheritData || null
      if(obj) {
        prop.split('.').forEach(p => {
          if(obj) {
            obj = obj[p]
          }
        })
      }


      return obj || placeholder
    },

    openCpoPrivateAccess() {
      this.$refs.cpoPrivateAccess.open()
    },
  },
}
</script>

<style lang="scss">
.panel {
  padding: 60px;
  align-items: center;
  justify-content: center;
}

.organization-form {
  .row + .bloc-headline {
    margin-top: 20px;
  }
  .contact {
    & > div {
      &  > div {
        padding: 15px;
        border: 1px solid #ccc;
        border-radius: 10px;
        position: relative;
        .headline-title {
          color: #758093;
          font-weight: 500;
        }
        .close {
          position: absolute;
          right: 0;
        }
      }

    }
    .add {
      min-height: 273px;
      .v-btn__content {
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }
}

</style>
