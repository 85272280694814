<template>
    <div class="organization-expense-refunds">


      <v-data-table
          class="subscriptions-list"
          :headers="headers"
          :loading="loading"
          :items="periods"
          :options.sync="options"
          :server-items-length="total"
      >


        <template #item.startDate="{item}">
          {{ formatUtcDate(item.startDate) }} <v-icon>mdi-chevron-right</v-icon> {{ formatUtcDate(item.endDate) }}
        </template>

        <template #item.energy="{value}">
          {{ numberFormat(value/1000, 3) }} kWh
        </template>

        <template #item.amount="{value}">
          {{ numberFormat(value) }} €
        </template>


        <template #item.options="{item}">
          <router-link v-if="item.nbBillables > 1" :to="{name: 'organizations.energy-provider.period', params: {periodId: item.id} }">
            <v-icon>mdi-arrow-right-thick</v-icon>
          </router-link>
          <router-link v-else :to="{name: 'organizations.energy-provider.period.lines', params: {periodId: item.id} }">
            <v-icon>mdi-arrow-right-thick</v-icon>
          </router-link>
        </template>

      </v-data-table>


    </div>
</template>

<script>

import ExpenseRefundRepository from "@repository/ExpenseRefundRepository";
import dayjs from "dayjs";
import EnergyProviderRepository from "@repository/EnergyProviderRepository";

export default {
  data() {
    return {
      loading: false,
      options: null,
      total: 0,
      headers: [
        {text: this.$tc('expense-refund.period'), value: 'startDate'},
        {text: this.$tc('energy'), value: 'energy'},
        {text: this.$tc('count'), value: 'nbLines'},
        {text: this.$tc('amount'), value: 'amount'},
        {value: 'options', width: 200},
      ],
      periods: [],
    }
  },

  mounted() {

  },

  watch: {

    options() {
      this.loadData()
    },

  },

  computed: {
    organization() {
      return this.$parent.organization ?? null
    }
  },

  methods: {
    loadData() {
      let filter = {}

      if (this.options.itemsPerPage > -1) {
        filter.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      filter.limit = this.options.itemsPerPage


      if (this.options.sortBy.length > 0) {
        filter.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        filter.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }
      this.loading = true
      EnergyProviderRepository.periods(this.organization?.id, filter).then(response => {
        this.total = response.total
        this.periods = response.items
        this.loading = false
      }).catch(err => {
        this.notifyError(err)
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">



</style>
