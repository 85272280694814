<template>
  <div class="member-search">

    <!-- Add button -->
    <v-row>
      <v-col class="text-right">
        <v-btn class="mr-3" small @click="openAddCustomer()" v-if="currentUser.isZE">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('customer.add-customer') }}
        </v-btn>
        <v-btn small @click="openInviteMember()">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('member.invite-member') }}
        </v-btn>
        <v-menu left offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                class="ml-3"
                small
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :to="{name: 'organizations.members.types'}">
              <v-list-item-title>{{ $t('member-type.manage') }}</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'organizations.members.groups'}">
              <v-list-item-title>{{ $t('member-group.manage') }}</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: 'organizations.members.invitations'}">
              <v-list-item-title>{{ $t('member.invitation.manage') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <!-- Search -->
    <v-row>
      <v-col>
        <search-field
            :placeholder="$t('member.search-member')"
            :search.sync="search"
            :options.sync="options"
            clearable
        >
        </search-field>
        <filter-by-letter
            :selectedLetter="startBy"
            @update:startBy="updateStartBy"
        />
      </v-col>
    </v-row>


    <!-- List -->
    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            class="member-list"
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            :server-items-length="total"
            @click:row="row => $router.push({ name: 'member', params: {memberId: row.id} })"
            :footer-props="{
              'items-per-page-options': [
                  20, 50, 100
              ]
            }"
        >
          <template #item.status="{ value }">
            {{ $t('member.status.' + value) }}
          </template>

          <template #item.homeChargingPointsCount="{ value }">
            {{ value > 0 ? $t('yes') : $t('no') }}
          </template>

<!--          <template #item.invitedAt="{ value }">-->
<!--            {{ value|formatDate }}-->
<!--          </template>-->

          <template #item.createdAt="{ value }">
            {{ value|formatDate }}
          </template>

          <template #item.groupsCount="{ item }">
            <member-group-type :item="group" :key="group.id"  v-for="group in item.groups"></member-group-type>
          </template>

          <template #item.tokensCount="{ value }">
            {{ value }}
          </template>

          <template #item.actions="{ item }">

            <!-- Delete -->
            <v-btn icon @click.stop="deleteMember(item)" :title="$t('delete')">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>

        </v-data-table>

      </v-col>
    </v-row>


    <!-- Modal: Invitation membre -->
    <invite-member-modal
        v-if="organization"
        ref="inviteMember"
        :organization="organization"
        @invitationSent="loadData('modal event')"
    ></invite-member-modal>

    <v-dialog v-model="addCustomerModal" max-width="600">
      <v-form @submit.prevent="addFromCustomer">
        <v-card>
          <v-card-title>
            {{ $t('customer.add-customer')}}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="my-6">
            <v-row>
              <v-col>
                <customer-select
                    v-model="customerIdToAdd"
                    :label="$tc('customer.customer')"
                    :placeholder="$t('customer.search-placeholder')"
                    clearable
                ></customer-select>
              </v-col>
            </v-row>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row class="ma-0">
              <v-col class="text-right">
                <v-btn small @click="addCustomerModal = false">{{ $t('cancel') }}</v-btn>
                <v-btn small class="ml-3" color="primary" type="submit" :disabled="!customerIdToAdd">{{ $t('add') }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
import MemberRepository from "@repository/MemberRepository";
import InviteMemberModal from "@/components/modals/InviteMemberModal.vue";
import MemberGroupType from "@blocks/MemberGroupType.vue";
import CustomerSelect from "@blocks/Select/CustomerSelect.vue";
import FilterByLetter from "@blocks/FilterByLetter.vue";

export default {
  name: 'OrganizationMembers',
  components: {
    FilterByLetter,
    MemberGroupType,
    InviteMemberModal,
    CustomerSelect,
  },

  data() {
    return {
      addCustomerModal: false,
      inviteMemberModal: {
        displayed: true
      },
      search: '',
      customerIdToAdd: null,
      loading: false,
      selectedItem: null,
      items: [],
      options: null,
      startBy: null,
      total: 0,
      headers: [
        {text: this.$t('customer.evco-id'), value: 'customer.evcoId'},
        {text: this.$t('lastname'), value: 'lastname'},
        {text: this.$t('firstname'), value: 'firstname'},
        {text: this.$t('member.external-reference'), value: 'externalReference'},
        {text: this.$t('type'), value: 'type.name'},
        {text: this.$tc('group', 2), value: 'groupsCount'},
        {text: this.$tc('member.home-charging-point', 2), value: 'homeChargingPointsCount'},
        {text: this.$tc('member.token', 2), value: 'tokensCount'},
        {text: this.$t('vehicle-registration'), value: 'vehicleRegistrationNumber'},
        {text: this.$t('status'), value: 'status'},
        // {text: this.$t('member.invited-at'), value: 'invitedAt'},
        {text: this.$t('member.joined-at'), value: 'createdAt'},
        {text: '', value: 'actions', sortable: false},
      ],
    }
  },

  computed: {
    organization() {
      return this.$parent.organization ?? null
    }
  },

  watch: {
    startBy() {
      this.loadData('search');
    },
    search() {
      if(this.options.page > 1) {
        this.options.page = 1
      } else {
        this.loadData('search')
      }
    },

    options() {
      this.loadData('options')
    },

    selectedItem(val) {
      this.$router.push({name: 'organizations.edit', params: {id: val.id} })
    },
  },

  methods: {
    updateStartBy(letter) {
      this.startBy = letter;
    },
    loadData(source) {
      this.loading = true

      let data = {
        searchText: this.search,
      }
      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      data.organizationId = this.organization.id

      if (this.options.sortBy.length > 0) {
        data.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }
      data.startBy = this.startBy
      MemberRepository.search(data).then(results => {
        this.total = results.total
        this.items = results.items.map(item => {
          item.groups = item.groups.toSorted(function(a, b) {
            let x = a.name?.toLowerCase(); let y = b.name?.toLowerCase();
            return x < y ? -1 : (x > y ? 1 : 0)
          })
          return item
        })
        this.loading = false
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.loading = false
      })
    },

    openAddCustomer() {
      this.customerIdToAdd = null
      this.addCustomerModal = true
    },

    openInviteMember() {
      this.$refs.inviteMember.open()
    },

    deleteMember(item) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          MemberRepository.deleteMember(item.id).then(() => {
            this.loadData()
            this.showLoading(false)
          }).catch(e => {
            this.showLoading(false)
            this.$dialog.notify.error(e.response?.data.message ?? e.message)
          })
        }
      })
    },

    addFromCustomer() {
      if(this.customerIdToAdd) {
        this.showLoading(true)
        MemberRepository.createFromCustomer(this.organization.id, this.customerIdToAdd).then(() => {
          this.addCustomerModal = false
          this.loadData()
          this.showLoading(false)
        }).catch(e => {
          this.showLoading(false)
          this.notifyError(e)
        })
      }
    },

  },
}
</script>

<style lang="scss">
  .member-search {
    min-height: 1000px;
    .member-list {
      td, th {
        cursor: pointer;
        &.text-start {
          text-align: left !important;
        }
      }
    }
  }


</style>
